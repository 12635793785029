import { CartApiAction } from '~/types/enums'
import type { Cart, Discount, DiscountCodeResponse, EmptoCreateCartResponse } from '~/types/types'
import { mapCartToEmptoCreateCartBody } from '~/util/empto'

export const useApiWrapper = () => {
  const offerStore = useOfferStore()
  async function checkZipcode(zipcode: string) {
    try {
      const data = await $fetch('/api/city', {
        params: { zipcode_code: zipcode },
      })
      return data
    } catch (error) {
      throw new Error('Error fetching zipcode')
    }
  }
  async function findOffer(fraction_id: number, zipcode: string) {
    try {
      const data = await $fetch<number[]>('/api/offer', {
        params: { fraction_id, zipcode },
      })
      return data
    } catch (error) {
      throw new Error('Error fetching offer')
    }
  }

  async function findAvailableFractionsFromZipcode(zipcode: string) {
    try {
      const data = await $fetch<number[]>('/api/fractions', {
        params: { zipcode },
      })
      offerStore.setAvailableFractionsForCity(data)
      return data
    } catch (error) {
      throw new Error('Error fetching available fractions')
    }
  }

  async function createCart(cart: Cart) {
    try {
      const emptoCreateCartBody = mapCartToEmptoCreateCartBody(cart)
      const data = await $fetch('/api/cart', {
        method: 'POST',
        body: {
          cart: emptoCreateCartBody,
        },
        query: { action: CartApiAction.create },
      })
      const emptoCreateCartResponse = data as EmptoCreateCartResponse
      if (
        !emptoCreateCartResponse ||
        !emptoCreateCartResponse.id ||
        !emptoCreateCartResponse.hash
      ) {
        throw new Error(JSON.stringify(emptoCreateCartResponse))
      }
      return emptoCreateCartResponse
    } catch (error) {
      throw new Error('Error creating cart')
    }
  }

  async function acceptCart(cartToAccept: EmptoCreateCartResponse, stripeTransactionId: string) {
    try {
      const data = await $fetch('/api/cart', {
        method: 'POST',
        body: {
          cartToAccept,
          stripeTransactionId,
        },
        query: { action: CartApiAction.accept },
      })
      return data
    } catch (error) {
      throw new Error('Error accepting cart')
    }
  }

  async function applyDiscountCode(discountCode: string): Promise<Discount | null> {
    const data = await $fetch('/api/discount?code=' + encodeURIComponent(discountCode))
    if (!data) {
      return null
    }
    const rawDiscount = data as DiscountCodeResponse
    return {
      id: rawDiscount.id,
      code: rawDiscount.code,
      percentage: rawDiscount.value_percent,
      value: rawDiscount.value,
    } as Discount
  }
  return {
    checkZipcode,
    findOffer,
    findAvailableFractionsFromZipcode,
    createCart,
    acceptCart,
    applyDiscountCode,
  }
}
