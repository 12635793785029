import type { City, ContainerOffer } from '@/types/types'
import { defineStore } from 'pinia'

export const useOfferStore = defineStore('current-offer-store', () => {
  const currentOffers = ref<ContainerOffer[]>([])
  const currentFractionId = ref<number>() // directus fraction id
  const currentCity = useCookie<City | undefined>('city', {
    maxAge: 60 * 60 * 24 * 7,
    sameSite: true,
  }) // city object
  const availableFractionsForCity = useCookie<number[]>('fractionInCity', {
    maxAge: 60 * 60 * 24 * 7,
    sameSite: true,
  })
  const { findAvailableFractionsFromZipcode } = useApiWrapper()

  const setCurrentOffers = (offers: ContainerOffer[]) => {
    currentOffers.value = offers
  }

  const setCurrentFractionId = (id: number) => {
    currentFractionId.value = id
  }

  const setCurrentCity = (city: City) => {
    currentCity.value = city
    findAvailableFractionsFromZipcode(city.zip)
  }
  const setAvailableFractionsForCity = (fractions: number[]) => {
    availableFractionsForCity.value = fractions
  }

  const reset = () => {
    currentFractionId.value = undefined
    currentOffers.value = []
    currentCity.value = undefined
    availableFractionsForCity.value = []
  }

  return {
    currentOffers,
    setCurrentOffers,
    currentFractionId,
    availableFractionsForCity,
    setCurrentFractionId,
    currentCity,
    setCurrentCity,
    setAvailableFractionsForCity,
    reset,
  }
})
