import {
  CONTAINER_DELIVERY_TIME_FRAME,
  EMPTO_CUSTOMER_TYPE,
  EMPTO_DELIVERY_TIME_FRAME,
  EMPTO_ORDER_TYPE,
  GENDER,
} from '~/types/enums'
import type { Cart, EmptoCreateCartBody } from '~/types/types'
import { createNewGermanDate } from './apiHelper'

const getEmptoPlacementTime = (
  containerPlacementTime: CONTAINER_DELIVERY_TIME_FRAME,
): EMPTO_DELIVERY_TIME_FRAME => {
  switch (containerPlacementTime) {
    case CONTAINER_DELIVERY_TIME_FRAME.MORNING:
      return EMPTO_DELIVERY_TIME_FRAME.MORNING
    case CONTAINER_DELIVERY_TIME_FRAME.AFTERNOON:
      return EMPTO_DELIVERY_TIME_FRAME.AFTERNOON
    default:
      return EMPTO_DELIVERY_TIME_FRAME.WHOLE_DAY
  }
}
const convertToEuroNumber = (centValue: number): number => {
  if (!centValue) {
    return 0
  }
  const value = centValue / 100
  return Number(value.toFixed(2))
}
const convertDateToEmptoFormat = (date: Date): string => {
  if (!date) {
    return ''
  }
  // returns date in format 'YYYY-MM-DD'
  let germanDateString = ''
  if (typeof date === 'string') {
    const originalDate = new Date(date)
    const localTime = originalDate.getTime()
    const localOffset = originalDate.getTimezoneOffset() * 60000
    const utc = localTime + localOffset
    const offset = +2 // UTC of Germany CEST (Central European Summer Time)
    const german = utc + 3600000 * offset
    germanDateString = new Date(german).toLocaleDateString('de-DE', {
      timeZone: 'Europe/Berlin',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
  } else {
    germanDateString = date.toLocaleDateString('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'Europe/Berlin',
    })
  }
  // why not simply using ISO string?
  // because it's gonna change the date to the next day or previous day depending on the timezone
  const [day, month, year] = germanDateString.split('.')

  return `${year}-${month}-${day}`
}
// maps cde cart to empto cart body paramenter
export const mapCartToEmptoCreateCartBody = (shopCart: Cart): EmptoCreateCartBody => {
  const { products, discount, customerDetails } = shopCart
  const { billingAddress, deliveryAddress, paymentMethod } = customerDetails
  let companyName = customerDetails.company !== '' ? customerDetails.company : undefined
  if (billingAddress.invoiceReceiver && billingAddress.invoiceReceiver !== '') {
    companyName = `${billingAddress.invoiceReceiver}`
  }
  const now = createNewGermanDate()
  return {
    gender: GENDER.MR, // TODO: should be removed, but current API still requires it
    company_name: companyName,
    first_name: customerDetails.firstName,
    last_name: customerDetails.lastName,
    customer_email: customerDetails.email,
    address_description:
      customerDetails.company && customerDetails.company !== ''
        ? customerDetails.company
        : `${customerDetails.firstName} ${customerDetails.lastName}`,
    tax_id: customerDetails.vatId !== '' ? customerDetails.vatId : undefined,
    customer_type: 1,
    customer_subtype: customerDetails.company
      ? EMPTO_CUSTOMER_TYPE.COMPANY
      : EMPTO_CUSTOMER_TYPE.PRIVATE,
    customer_telephone: deliveryAddress.phoneNumber,
    street: deliveryAddress.street,
    house_number: deliveryAddress.houseNumber,
    zipcode: deliveryAddress.postalCode,
    city: deliveryAddress.city,
    ...(billingAddress.invoiceReceiver
      ? {
          different_invoicing_address: true,
          invoice_recipient: billingAddress.receiverAttachment
            ? billingAddress.receiverAttachment
            : '',
          invoice_street: billingAddress.billingStreet,
          invoice_house_number: billingAddress.billingHouseNumber,
          invoice_zipcode: billingAddress.billingPostalCode,
          invoice_city: billingAddress.billingCity,
        }
      : {
          different_invoicing_address: false,
        }),

    ...(discount?.id
      ? {
          discount_id: discount.id,
        }
      : {}),
    payment_method: paymentMethod || 11,
    email_note: deliveryAddress.deliveryNotes,
    data: products.map(product => ({
      fraction: product.emptoFractionId,
      container: product.emptoContainerId,
      offer_container_price: convertToEuroNumber(product.unitPrice.grossPrice),
      auto_offer: product.offerId,
      number_of_containers: product.quantity,
      offer_end_price: convertToEuroNumber(product.totalProductPrice?.grossPrice as number),
      order_type: EMPTO_ORDER_TYPE.ONE_TIME,
      delivery_date: convertDateToEmptoFormat(product.deliveryDate[0]),
      collection_date: convertDateToEmptoFormat(
        product.deliveryDate[product.deliveryDate.length - 1],
      ),
      time_of_day_delivery:
        product.containerPlacementTime !== null
          ? getEmptoPlacementTime(product.containerPlacementTime)
          : EMPTO_DELIVERY_TIME_FRAME.WHOLE_DAY,
      time_of_day_collection: EMPTO_DELIVERY_TIME_FRAME.WHOLE_DAY,
      runtime_of_inquiry: convertDateToEmptoFormat(new Date(now.getTime() + 1000 * 60 * 60 * 24)), // tomorrow
      is_compulsory_offer: true,
      is_order_of_framework_contract: false,
      position_on_public_ground: deliveryAddress.positionOnPublicGround,
      has_additional_delivery_fee: false,
    })),
  }
}
